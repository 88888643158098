import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from "../components/Layout";
import {RichText} from "prismic-reactjs";
import Img from "gatsby-image";
import {formatDate} from "../utils/dateFormat";


export const Post = ({ location, path, data }) => {
    if (!data) return null
    const post = data.allPrismicPost.edges[0].node.data;
    const category = post.category.document;

    const firstPublicationDate = data.allPrismicPost.edges[0].node.first_publication_date;
    const postTitle = RichText.asText(post.post_title.raw);
    const postContent = RichText.render(post.post_content.raw);
    const featuredImage = post.featured_image;
    const categoryTitle = RichText.asText(category?.data.category_title.raw);

    return (
        <Layout location={location} path={path}>
            <article className="blog-post">
                <header className="blog-post-header">
                    <Img fluid={featuredImage.fluid} alt={featuredImage.alt} />
                    <div className="blog-post-header__gradient" />
                    <div className="blog-post-header__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>{postTitle}</h1>
                                    <div className="blog-post-header__subtitle">{categoryTitle} — {formatDate(firstPublicationDate)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="blog-post__breadcrumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/blog">{categoryTitle}</Link></li>
                                    <li>{postTitle}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-post__content">
                                {postContent}
                            </div>
                        </div>
                    </div>
                </div>

            </article>
        </Layout>
    )
}

export const query = graphql`
  query PageQuery($uid: String) {
    allPrismicPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          url
          id
          type
          first_publication_date
          last_publication_date
          data {
            featured_image {
                alt
                fluid(maxHeight: 580) {
                    ...GatsbyPrismicImageFluid
                }
            }
            post_title {
                raw
            }
            post_content {
                raw
            }         
            category {
              document {
                ... on PrismicCategory {
                    id
                    data {
                        category_title {
                            raw
                        }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Post;
